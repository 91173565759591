<template>

  <div>
    <user-sidebar
      v-model="isUserHandlerSidebarActive"
      :role-options="roleOptions"
      :user="userData"
      :clear-data="resetUserData"
      @add-user="addUser"
      @update-user="updateUser"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de usuarios</h3> <small> (Hay {{ totalUsers }} usuarios)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-can-permissions="'Crear usuario'"
                variant="primary"
                @click="isUserHandlerSidebarActive = true"
              >
                <span class="text-nowrap">Crear</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      >

        <!-- Column: Client -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                variant="light-primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }} {{ data.item.lastname }}
            </span>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <span v-can-permissions="'Editar usuario'">
              <feather-icon
                :id="`users-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="editUser(data.item)"
              />
              <b-tooltip
                title="Editar"
                class="cursor-pointer"
                :target="`users-row-${data.item.id}-edit-icon`"
              />

            </span>
            <span v-can-permissions="'Eliminar usuario'">
              <feather-icon
                :id="`users-row-${data.item.id}-delete-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="deleteUser(data.item)"
              />
              <b-tooltip
                title="Eliminar"
                :target="`users-row-${data.item.id}-delete-icon`"
              />
            </span>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BMedia, BAvatar, BPagination, BTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import userStoreModule from '@/views/dramox/user/userStoreModule'
import userHandler from '@/views/dramox/user/userHandler'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import UserSidebar from '@/views/dramox/user/sidebar/userSidebar.vue'

export default {
  components: {
    UserSidebar,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-users'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const blankUserData = {
      name: '',
      lastname: '',
      email: '',
      mobile: '',
      password: '',
      role_id: '',
    }
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const isUserHandlerSidebarActive = ref(false)
    const roleOptions = ref([])
    const userItem = ref({ })

    const {
      // fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      perPageOptions,
      searchQuery,
      refUserListTable,
      fetchUsers,
      refetchData,

      // UI
      resolveRoleVariant,
      resolveRoleIcon,
    } = userHandler()

    const editUser = item => {
      // isEditNewUserSidebarActive.value = true
      userData.value = item
      isUserHandlerSidebarActive.value = true
    }

    const addUser = item => {
      store.dispatch('app-users/addUser', item)
        .then(() => {
          refetchData()
          showNotification({ title: 'Creado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        }).catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const updateUser = item => {
      store.dispatch('app-users/updateUser', { id: item.id, body: item })
        .then(() => {
          refetchData()
          showNotification({ title: 'Actualizado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        }).catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteUser = item => {
      store.dispatch('app-users/deleteUser', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        }).catch(e => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    store.dispatch('app-users/fetchRoles')
      .then(response => {
        roleOptions.value = response.data.payload.results
      })
      .catch(error => {
        console.log(error)
      })

    return {
      // Sidebar
      isUserHandlerSidebarActive,

      userItem,
      userData,

      resetUserData,

      editUser,
      deleteUser,
      addUser,
      updateUser,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      perPageOptions,
      searchQuery,
      refUserListTable,
      refetchData,
      roleOptions,

      // Filter
      avatarText,

      // UI
      resolveRoleVariant,
      resolveRoleIcon,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
