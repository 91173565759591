import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function userHandler() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: false },
    { key: 'name', label: 'Nombre', sortable: false },
    { key: 'role', label: 'Rol', sortable: false },
    { key: 'email', label: 'Email', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-users/fetchUsers', {
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const { results } = response.data.payload

        callback(results)
        totalUsers.value = results.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'administrador') return 'SettingsIcon'
    if (role === 'vendedor') return 'ShoppingCartIcon'
    return 'UserIcon'
  }

  const resolveRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'administrador') return 'danger'
    if (role === 'vendedor') return 'success'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    perPageOptions,
    refUserListTable,

    resolveRoleVariant,
    resolveRoleIcon,
    refetchData,
  }
}
