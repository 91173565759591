import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/users')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, body) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/user/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/user/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/user/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/roles')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
