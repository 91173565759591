<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isUserHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-user-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ ObjectLocal.id ? 'Editar usuario' : 'Agregar usuario' }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Nombre"
              rules="required"
            >
              <b-form-group
                label="Nombre"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="ObjectLocal.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Lastname -->
            <validation-provider
              #default="validationContext"
              name="Apellido"
              rules="required"
            >
              <b-form-group
                label="Apellido"
                label-for="lastname"
              >
                <b-form-input
                  id="lastname"
                  v-model="ObjectLocal.lastname"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="ObjectLocal.email"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Mobile -->
            <b-form-group
              label="Celular"
              label-for="mobile"
            >
              <b-form-input
                id="mobile"
                v-model="ObjectLocal.mobile"
                type="number"
                trim
              />
            </b-form-group>

            <!-- Password -->
            <validation-provider
              #default="validationContext"
              name="Contraseña"
              :rules=" { required : !ObjectLocal.id }"
            >
              <b-form-group
                label="Contraseña"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="ObjectLocal.password"
                  type="password"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Role -->
            <validation-provider
              #default="validationContext"
              name="Rol"
              rules="required"
            >
              <b-form-group
                label="Rol"
                label-for="role"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="ObjectLocal.role_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(option) => option.id"
                  input-id="contact"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ ObjectLocal.id ? 'Actualizar' : 'Agregar ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancelar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import userHandlerSidebar from '@/views/dramox/user/sidebar/userHandlerSidebar'
import vSelect from 'vue-select'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isUserHandlerSidebarActive',
    event: 'update:is-user-handler-sidebar-active',
  },
  props: {
    isUserHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    clearData: {
      type: Function,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    const {
      ObjectLocal,
      resetLocal,
      onSubmit,
    } = userHandlerSidebar(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetLocal, props.clearData)

    return {
      // Add New
      ObjectLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
</style>
